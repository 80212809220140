<template>
  <div
    class="col-span-4 row-span-1 rounded-t-lg bg-ds-button-secondary-background p-3 lg:col-span-6"
    :class="className"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'FullRow',
    props: {
      className: String,
      default: String(''),
    },
  };
</script>
