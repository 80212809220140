<template>
  <SlideOver
    ref="plansSlideOver"
    @close="dismiss"
    :enableMobileBottomToTopTranslation="true"
    :open="showPlansModal"
    containerClasses="h-screen w-screen bg-inherit flex justify-center items-end md:items-center"
  >
    <div
      class="relative w-full overflow-y-auto rounded-lg bg-ds-foreground pb-5 md:w-auto md:pb-8"
      style="max-height: 670px"
    >
      <p class="pl-4 pt-4 text-2xl font-bold md:pl-8 md:pt-8">Plans</p>
      <div class="hidden items-center gap-3 pl-5 md:flex md:pl-8">
        <p class="text-lg text-gray-80 dark-mode:text-dark-mode-50">Billing</p>
        <ToggleInput :toggle="isSubscriptionTypeMonthly">
          <template slot="left">
            <p @click="changeSubscriptionType(true)">Monthly</p>
          </template>
          <template slot="right">
            <p @click="changeSubscriptionType(false)">Yearly</p>
          </template>
        </ToggleInput>
      </div>
      <inline-svg
        @click="closePlansModal"
        class="absolute right-4 top-4 h-5 w-5 cursor-pointer text-blues-40 md:right-8 md:top-8"
        src="/img/icons/close.svg"
      />
      <div class="grid w-full grid-cols-4 px-4 md:px-8 lg:grid-cols-6">
        <div class="sticky top-0 col-span-2 hidden bg-ds-foreground lg:block"></div>
        <HeaderCell v-if="selectedSubscriptionType['Standard']">
          <div>
            <p class="text-base font-medium md:text-xl">Starter (standard)</p>
            <p class="hidden text-2xl font-bold text-blues-50 md:block">
              {{ selectedSubscriptionType['Standard'].displayPrice }}/{{
                isSubscriptionTypeMonthly ? 'month' : 'year'
              }}
            </p>
            <p class="hidden text-sm text-gray-50 md:block">VAT Excl.</p>
          </div>
          <SelectPlanBtn
            v-on:checkout="checkout($event)"
            size="sm"
            :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
            plan="standard"
          />
        </HeaderCell>
        <HeaderCell v-if="selectedSubscriptionType['Premium']">
          <div>
            <p class="font text-base font-medium md:text-xl">Creator (premium)</p>
            <p class="hidden text-2xl font-bold text-blues-50 md:block">
              {{
                `${selectedSubscriptionType['Premium'].displayPrice}/${
                  isSubscriptionTypeMonthly ? 'month' : 'year'
                }`
              }}
            </p>
            <p class="hidden text-sm text-gray-50 md:block">VAT Excl.</p>
          </div>
          <SelectPlanBtn
            v-on:checkout="checkout($event)"
            size="sm"
            :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
            plan="premium"
          />
        </HeaderCell>
        <HeaderCell v-if="selectedSubscriptionType['Business']">
          <div>
            <p class="font text-base font-medium md:text-xl">Business</p>
            <p class="hidden text-2xl font-bold text-blues-50 md:block">
              {{
                `${selectedSubscriptionType['Business'].displayPrice}/${
                  isSubscriptionTypeMonthly ? 'month' : 'year'
                }`
              }}
            </p>
            <p class="hidden text-sm text-gray-50 md:block">VAT Excl.</p>
          </div>
          <SelectPlanBtn
            v-on:checkout="checkout($event)"
            size="sm"
            :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
            plan="business"
          />
        </HeaderCell>
        <HeaderCell v-if="selectedSubscriptionType['Agency']">
          <div>
            <p class="font text-base font-medium md:text-xl">Agency</p>
            <p class="hidden text-2xl font-bold text-blues-50 md:block">
              {{
                `${selectedSubscriptionType['Agency'].displayPrice}/${
                  isSubscriptionTypeMonthly ? 'month' : 'year'
                }`
              }}
            </p>
            <p class="hidden text-sm text-gray-50 md:block">VAT Excl.</p>
          </div>
          <SelectPlanBtn
            v-on:checkout="checkout($event)"
            size="sm"
            :isSubscriptionTypeMonthly="isSubscriptionTypeMonthly"
            plan="agency"
          />
        </HeaderCell>
        <FullRow>
          <p class="text-xl font-bold">Inspiration</p>
        </FullRow>
        <RowTitleCell>
          <p>Viral tweets from 14 niches</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Viral thread hooks</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Tweet templates</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>1000+ example questions</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Your best tweets</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <FullRow class-name="mt-5">
          <p class="text-xl font-bold">Social media platforms</p>
        </FullRow>
        <RowTitleCell>
          <p>Connected</p>
          <svg class="h-3 w-3" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.44528 5.51116L11.6333 0.75H10.6409L7.00441 4.88405L4.09995 0.75H0.75L5.14211 7.00142L0.75 11.9942H1.74249L5.58273 7.62853L8.65005 11.9942H12L7.44528 5.51116ZM5.64091 6.43399L9.80375 10.125L3.62452 1.48069L6.48199 5.47816L6.927 6.10066L5.64091 6.43399Z"
              fill="#0078FB"
            />
          </svg>
          <p>accounts</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p>{{ getAdditionalAccount('standard') }}</p>
        </RowCell>
        <RowCell>
          <p>{{ getAdditionalAccount('premium') }}</p>
        </RowCell>
        <RowCell>
          <p>{{ getAdditionalAccount('business') }}</p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>{{ getAdditionalAccount('agency') }}</p>
        </RowCell>
        <RowTitleCell>
          <p>Managed</p>
          <svg class="h-3 w-3" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.44528 5.51116L11.6333 0.75H10.6409L7.00441 4.88405L4.09995 0.75H0.75L5.14211 7.00142L0.75 11.9942H1.74249L5.58273 7.62853L8.65005 11.9942H12L7.44528 5.51116ZM5.64091 6.43399L9.80375 10.125L3.62452 1.48069L6.48199 5.47816L6.927 6.10066L5.64091 6.43399Z"
              fill="#0078FB"
            />
          </svg>
          <p>accounts</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p>{{ getManagedAccounts('standard') }}</p>
        </RowCell>
        <RowCell>
          <p>{{ getManagedAccounts('premium') }}</p>
        </RowCell>
        <RowCell>
          <p>{{ getManagedAccounts('business') }}</p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>{{ getManagedAccounts('agency') }}</p>
        </RowCell>
        <RowTitleCell>
          <p>Linkedin personal</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Linkedin business</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Instagram</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Facebook Pages & Groups</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <FullRow class-name="mt-5">
          <p class="text-xl font-bold">Features</p>
        </FullRow>
        <RowTitleCell>
          <p>Schedule posts up to...</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p>{{ getScheduling('standard') }}</p>
        </RowCell>
        <RowCell>
          <p>{{ getScheduling('premium') }}</p>
        </RowCell>
        <RowCell>
          <p>{{ getScheduling('business') }}</p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>{{ getScheduling('agency') }}</p>
        </RowCell>
        <RowTitleCell>
          <p>Viral post inspiration</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Thread preview</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Tweet booster</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Autoplugs</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Save drafts</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p>{{ convertInfinityToUnlimited(formattedPlans['standard'].limits.drafts) }}</p>
        </RowCell>
        <RowCell>
          <p>{{ convertInfinityToUnlimited(formattedPlans['premium'].limits.drafts) }}</p>
        </RowCell>
        <RowCell>
          <p>{{ convertInfinityToUnlimited(formattedPlans['business'].limits.drafts) }}</p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>{{ convertInfinityToUnlimited(formattedPlans['agency'].limits.drafts) }}</p>
        </RowCell>
        <RowTitleCell>
          <p>Engagement builder: watched users</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p>
            {{ convertInfinityToUnlimited(formattedPlans['standard'].limits.feed.watchedUsers) }}
          </p>
        </RowCell>
        <RowCell>
          <p>
            {{ convertInfinityToUnlimited(formattedPlans['premium'].limits.feed.watchedUsers) }}
          </p>
        </RowCell>
        <RowCell>
          <p>
            {{ convertInfinityToUnlimited(formattedPlans['business'].limits.feed.watchedUsers) }}
          </p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>{{ convertInfinityToUnlimited(formattedPlans['agency'].limits.feed.watchedUsers) }}</p>
        </RowCell>
        <RowTitleCell>
          <p>Engagement builder: watched search terms</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p>
            {{
              convertInfinityToUnlimited(formattedPlans['standard'].limits.feed.watchedSearchTerms)
            }}
          </p>
        </RowCell>
        <RowCell>
          <p>
            {{
              convertInfinityToUnlimited(formattedPlans['premium'].limits.feed.watchedSearchTerms)
            }}
          </p>
        </RowCell>
        <RowCell>
          <p>
            {{
              convertInfinityToUnlimited(formattedPlans['business'].limits.feed.watchedSearchTerms)
            }}
          </p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>
            {{
              convertInfinityToUnlimited(formattedPlans['agency'].limits.feed.watchedSearchTerms)
            }}
          </p>
        </RowCell>
        <RowTitleCell>
          <p>Engagement builder: Import</p>
          <svg class="h-3 w-3" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.44528 5.51116L11.6333 0.75H10.6409L7.00441 4.88405L4.09995 0.75H0.75L5.14211 7.00142L0.75 11.9942H1.74249L5.58273 7.62853L8.65005 11.9942H12L7.44528 5.51116ZM5.64091 6.43399L9.80375 10.125L3.62452 1.48069L6.48199 5.47816L6.927 6.10066L5.64091 6.43399Z"
              fill="#0078FB"
            />
          </svg>
          <p>lists</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Statistics (likes & retweet counts)</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Delay threaded tweets</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Schedule replies</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>CSV upload</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Automatic reposting best tweets</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Auto post tweets to Instagram</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Auto post tweets to Facebook</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <svg class="h-3 w-3" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.44528 5.51116L11.6333 0.75H10.6409L7.00441 4.88405L4.09995 0.75H0.75L5.14211 7.00142L0.75 11.9942H1.74249L5.58273 7.62853L8.65005 11.9942H12L7.44528 5.51116ZM5.64091 6.43399L9.80375 10.125L3.62452 1.48069L6.48199 5.47816L6.927 6.10066L5.64091 6.43399Z"
              fill="#0078FB"
            />
          </svg>
          <p>AutoDMs</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p>{{ formattedPlans['standard'].limits.dailyAutoDMs }} per day</p>
        </RowCell>
        <RowCell>
          <p>{{ formattedPlans['premium'].limits.dailyAutoDMs }} per day</p>
        </RowCell>
        <RowCell>
          <p>{{ formattedPlans['business'].limits.dailyAutoDMs }} per day</p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>{{ formattedPlans['agency'].limits.dailyAutoDMs }} per day</p>
        </RowCell>
        <RowTitleCell>
          <p>Recurrent posts</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Recurrent posts categories</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p>{{ convertInfinityToUnlimited(formattedPlans['standard'].limits.categories) }}</p>
        </RowCell>
        <RowCell>
          <p>{{ convertInfinityToUnlimited(formattedPlans['premium'].limits.categories) }}</p>
        </RowCell>
        <RowCell>
          <p>{{ convertInfinityToUnlimited(formattedPlans['business'].limits.categories) }}</p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>{{ convertInfinityToUnlimited(formattedPlans['agency'].limits.categories) }}</p>
        </RowCell>
        <RowTitleCell>
          <p>Import all your tweets</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p></p>
        </RowCell>
        <RowCell>
          <p>Once per day</p>
        </RowCell>
        <RowCell>
          <p>Once per day</p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>Once per day</p>
        </RowCell>
        <RowTitleCell>
          <p>Analytics: charts and insights</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p>Up to 7 days</p>
        </RowCell>
        <RowCell>
          <p>Unlimited</p>
        </RowCell>
        <RowCell>
          <p>Unlimited</p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>Unlimited</p>
        </RowCell>
        <RowTitleCell>
          <p>Run Gumroad sales on Auto-pilot</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <p>{{ getWeeklySales('standard') }}</p>
        </RowCell>
        <RowCell>
          <p>{{ getWeeklySales('premium') }}</p>
        </RowCell>
        <RowCell>
          <p>{{ getWeeklySales('business') }}</p>
        </RowCell>
        <RowCell class-name="border-r">
          <p>{{ getWeeklySales('agency') }}</p>
        </RowCell>
        <RowTitleCell>
          <p>Schedule retweets of other people</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <FullRow class-name="mt-5">
          <p class="text-xl font-bold">Support</p>
        </FullRow>
        <RowTitleCell>
          <p>Basic support</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox />
        </RowCell>
        <RowCell>
          <PlanCheckBox />
        </RowCell>
        <RowTitleCell>
          <p>Premium email support</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Live chat support</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Weekend support</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox />
        </RowCell>
        <RowCell>
          <PlanCheckBox />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <FullRow class-name="mt-5">
          <p class="text-xl font-bold">Analytics</p>
        </FullRow>
        <RowTitleCell>
          <p>Statistics (like & retweets counts)</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Optional public analytics profile</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Follower growth & profile clicks</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
        <RowTitleCell>
          <p>Filter top tweets</p>
        </RowTitleCell>
        <RowCell class-name="border-l mlg:border-l-0">
          <PlanCheckBox />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell>
          <PlanCheckBox isChecked />
        </RowCell>
        <RowCell class-name="border-r">
          <PlanCheckBox isChecked />
        </RowCell>
      </div>
    </div>
  </SlideOver>
</template>

<script>
  import CustomerStatusMixin from '../../views/Mixins/CustomerStatusMixin.vue';
  import SlideOver from '@/components/SlideOver.vue';
  import ToggleInput from '@/components/Inputs/ToggleInput.vue';
  import HeaderCell from '@/components/Subscription/Table/HeaderCell.vue';
  import PlanCheckBox from '@/components/Subscription/PlanCheckBox.vue';
  import FullRow from '@/components/Subscription/Table/FullRow.vue';
  import RowTitleCell from '@/components/Subscription/Table/RowTitleCell.vue';
  import RowCell from '@/components/Subscription/Table/RowCell.vue';
  import SelectPlanBtn from '@/components/Subscription/SelectPlanBtn.vue';
  import { PLANS } from '../../../functions/src/util/customerStatus.cjs';

  export default {
    name: 'PlansModal',
    components: {
      SlideOver,
      ToggleInput,
      HeaderCell,
      FullRow,
      RowTitleCell,
      RowCell,
      SelectPlanBtn,
      PlanCheckBox,
    },
    props: {
      showPlansModal: {
        type: Boolean,
        required: true,
      },
      isSubscriptionTypeMonthly: {
        type: Boolean,
        required: true,
      },
      selectedSubscriptionType: {
        type: Object,
        required: true,
      },
    },
    methods: {
      dismiss() {
        this.$emit('close');
      },
      closePlansModal() {
        const plansSlideOver = this.$refs.plansSlideOver;
        if (plansSlideOver) {
          plansSlideOver.dismiss();
        }
      },
      changeSubscriptionType(value) {
        this.$emit('changeSubscriptionType', value);
      },
      checkout(value) {
        this.$emit('checkout', value);
      },
      getAdditionalAccount(plan) {
        if (!this.formattedPlans[plan]) return '';

        // We need to add 1 to count for initial count
        return this.formattedPlans[plan].limits.additionalAccounts.new + 1;
      },
      getManagedAccounts(plan) {
        if (!this.formattedPlans[plan]) return '';

        const selectedPlan = this.formattedPlans[plan];
        return selectedPlan.limits.managedAccounts ? selectedPlan.limits.managedAccounts : '';
      },
      convertInfinityToUnlimited(value) {
        return value === Infinity ? 'Unlimited' : value;
      },
      getScheduling(plan) {
        if (!this.formattedPlans[plan]) return '';

        const selectedPlan = this.formattedPlans[plan];

        return selectedPlan.limits.scheduling.value === Infinity
          ? 'Unlimited'
          : `${selectedPlan.limits.scheduling.value} ${selectedPlan.limits.scheduling.unit}`;
      },
      getWeeklySales(plan) {
        const selectedPlan = this.formattedPlans[plan];

        return selectedPlan.limits.weeklySales === Infinity
          ? 'Unlimited'
          : `${selectedPlan.limits.weeklySales} per week`;
      },
    },
    computed: {
      formattedPlans() {
        const formatted = {};
        const plans = Object.entries(PLANS);

        const plansArray = plans.map(([key, value]) => {
          return {
            name: key,
            ...value,
          };
        });

        plansArray.forEach((plan) => {
          formatted[plan.name] = { ...plan };
        });

        return formatted;
      },
    },
    mixins: [CustomerStatusMixin],
  };
</script>

<style scoped>
  #select-btn {
    display: none;
  }
  @media (min-width: 768px) {
    #select-btn {
      display: block;
    }
  }
</style>
