<template>
  <span
    class="h-5 w-5 rounded-sm"
    :class="{
      'flex items-center justify-center bg-ds-button-icon': isChecked,
      'border border-ds-outline-primary': !isChecked,
    }"
  >
    <inline-svg
      v-if="isChecked"
      class="w-1/2 text-ds-background"
      src="/img/icons/new/check-solid.svg"
    ></inline-svg>
  </span>
</template>

<script>
  export default {
    name: 'PlanCheckBox',
    props: {
      isChecked: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
