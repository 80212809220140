<template>
  <div
    class="border relative h-10 w-40 overflow-hidden rounded-full border-ds-outline-primary bg-ds-background p-1"
  >
    <div class="relative flex h-full w-full items-center justify-between">
      <span
        class="absolute top-0 z-40 h-full w-1/2 rounded-full bg-ds-outline-secondary"
        :class="toggle ? 'left-0' : 'right-0'"
      />
      <div class="relative z-50 w-1/2 cursor-pointer text-center text-ds-text-primary">
        <slot name="left" />
      </div>
      <div class="relative z-50 w-1/2 cursor-pointer text-center text-ds-text-primary">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'toggle-input',
    props: {
      toggle: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
