<template>
  <div
    class="border-b lg:border-r col-span-1 flex items-center justify-center border-ds-outline-secondary pb-3 lg:p-0"
    :class="className"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'RowCell',
    props: {
      className: String,
      default: String(''),
    },
  };
</script>
