<template>
  <div
    class="sticky top-0 col-span-1 flex flex-col justify-between gap-3 bg-ds-foreground px-1 py-2 pb-5 md:px-5"
    :class="className"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'HeaderCell',
    props: {
      className: String,
      default: String(''),
    },
  };
</script>
